import {DatePipe} from '@angular/common';

export class Utility {
  constructor(private  datePipe: DatePipe) {
  }
  public static  serviceStorageURL() {
    return localStorage.getItem('serviceStorageURL');
  }
  public static junctionboxPath = localStorage.getItem('junctionBoxPathUrl');

  public static junctionboxURL() {
    return localStorage.getItem('junctionBoxPathUrl');
  }

  // public static serviceAnalyticsURL = "https://profittrackerserviceanalytics.octanics.io"; /*server*/

  public static getLoggedUserId() {
    return   localStorage.getItem('employeeid');
  }
  public static getCompanyName = localStorage.getItem('companyname');

  public static getCompanyShortNameURL() {
    return localStorage.getItem('companyshortname');
  }
  public static getDateTime() {
    var datePipe = new DatePipe('en-IN');
    return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
  }

  public static currentYear () {
    const currentdate = new Date();
    return currentdate.getFullYear();
  }
}

