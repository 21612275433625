import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Response, ResponseContentType} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Utility} from '../../../common/utility';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Common} from '../../../common/common';
import {SignaturePad} from 'angular4-signaturepad/signature-pad';
// tslint:disable-next-line:import-blacklist
import 'rxjs/Rx';
import {DatePipe} from '@angular/common';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';

declare var $: any;


@Component({
  selector: 'app-handingoverreportview',
  templateUrl: './handingoverreportview.component.html',
  styleUrls: ['./handingoverreportview.component.scss']
})
export class HandingoverreportviewComponent implements OnInit {


  constructor(private http: Http, private httpclient: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private title: Title, private lookUp: Lookup) {
  }

  id: number;
  todayDate: any;
  jobWorkDetails = [];
  jobWorkAssignmentHistory = [];
  employerDetails: any = [];
  currentYear = Utility.currentYear();
  HandingOverReportForm: FormGroup;
  companyShortName = Utility.getCompanyShortNameURL();
  junctionBoxSubDomain;
  pdf: any;
  selectedFilePath: string;
  errorMessage = '';
  successMessage = '';
  systemapi = [];
  amcDetails = [];
  handedoversignature: any;
  acceptedsignature: any;
  signatureValidationMessage = '';
  acceptedSignatureURL = false;
  handedOverSignatureURL = false;
  acceptedTime: any;
  jobWorkTypeId: number;
  productDetails = [];
  addresstypes = [];
  junctionBox;
  employeeDetail;
  employeeId;
  prodreferId: number;
  registeredId: number;
  companyname: string;
  // tslint:disable-next-line:variable-name
  business_id;

  getSystemApis() {
    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage['url']);
    });

  }


  selectchange(id, jobWorkTypeId) {
    this.prodreferId = id;
    this.jobWorkTypeId = jobWorkTypeId;
    if (this.jobWorkTypeId != null) {
      // tslint:disable-next-line:max-line-length
      this.http.get(Configuration.serviceCoreURL  + '/gethandingoverreportproductdetails?id=' + this.prodreferId + '&jobworktypeid=' + this.jobWorkTypeId).subscribe(data => {
        if (data['_body'] !== '' && data['_body'] !== null && data['_body'] !== undefined) {
          this.productDetails = data.json();
        }
      });
    }
  }
  getJobWorkDetail() {
    this.http.get(Configuration.serviceCoreURL  + '/gethandingoverreportjobworkdetails?id=' + this.id).subscribe(data => {
      data = data.json();
      if ( data['jobWorkDetails'] !== null && data['jobWorkDetails'] !== undefined && data['jobWorkDetails'] !== '' ) {
        this.jobWorkDetails = data['jobWorkDetails'];
        if (this.jobWorkDetails !== null) {
          this.business_id = this.jobWorkDetails['business_id'];
          localStorage.setItem('business_id', this.business_id);
          // tslint:disable-next-line:no-shadowed-variable
          this.lookUp.getLookUp('addresstypes').subscribe((data) => {
            if (data) {
              this.addresstypes = data;
              const type = this.addresstypes.find(x => x.code === 'REGISTERED');
              this.registeredId = type['id'];
            }
            // tslint:disable-next-line:max-line-length
            this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
              data = data.json();
              this.employerDetails = data;
              localStorage.setItem('companyname', data['nameoforganization']);
              localStorage.setItem('companymobileno', data['mobilenoofowner']);
              localStorage.setItem('companyshortname', data['companyshortname']);
            });
          });
          this.jobWorkAssignmentHistory = data['jobWorkAssignmentHistory'];
          this.selectchange(this.jobWorkDetails['reference_id'], this.jobWorkDetails['jobworktype_id']);
          if (this.jobWorkDetails['companyname'] == '') {
            this.companyname = this.jobWorkDetails['customerName'];
          } else {
            this.companyname = this.jobWorkDetails['companyname'];
          }
        }
      }
    });
  }

  ngOnInit() {
    this.getSystemApis();
    this.todayDate = Utility.getDateTime();
    const datePipe = new DatePipe('en-IN');
    this.acceptedTime = datePipe.transform(this.todayDate, 'hh : mm : ss');

    this.title.setTitle('Octanics - Handing Over Report');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });
    this.getJobWorkDetail();


    this.http.get(Configuration.serviceCoreURL  + '/getlastassignmenthistoryemployeeid?id=' + this.id).subscribe(data => {
      this.employeeDetail = data.json();
      this.employeeId = this.employeeDetail != null ? this.employeeDetail['employee_id'] : -1;
    });


    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionBox = localStorage.getItem('junctionBoxPathUrl');

      const junctionboxsubdomainpath = this.systemapi.find(x => x.name === 'junctionboxsubdomain');
      localStorage.setItem('junctionBoxSubDomainPath', junctionboxsubdomainpath.url);
      this.junctionBoxSubDomain = localStorage.getItem('junctionBoxSubDomainPath');

      if (this.junctionBoxSubDomain === 'null') {
        this.junctionBoxSubDomain = null;
      }
    });

  }


}
