import { Component, OnInit } from '@angular/core';
import {Validate} from '../../common/validate';
import {Utility} from '../../common/utility';
import {Http, ResponseContentType} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Common} from '../../common/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Configuration} from '../../../assets/general/configuration';
import {Lookup} from '../../common/lookup';
import {AlertService} from '../../common/alert.service';
declare var $: any;



@Component({
  selector: 'app-otpverification',
  templateUrl: './otpverification.component.html',
  styleUrls: ['./otpverification.component.scss']
})
export class OtpverificationComponent implements OnInit {

  constructor(private http: Http , private router: Router,
              private route: ActivatedRoute,
              private title: Title, private lookUp: Lookup, private alertService: AlertService) { }
  Validate = Validate;
  codeValidation = '';
  id: number;
  registeredId: number;
  addresstypes = [];
  OtpForm: FormGroup;
  employerDetails = [];
  junctionbox;
  systemapi = [];
  // tslint:disable-next-line:variable-name
  business_id;



  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        const type = this.addresstypes.find(x => x.code === 'REGISTERED');
        this.registeredId = type['id'];
      }
      // tslint:disable-next-line:max-line-length no-shadowed-variable
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
      });
    });
  }

  getOtp(otp) {
    if (otp === '') {
      this.codeValidation = '';
    } else {
      this.http.get(Configuration.serviceCoreURL + '/getvalidotp?id=' + this.id + '&otp=' + otp).subscribe(data => {
        if (data['_body'] !== '' && data['_body'] !== null && data['_body'] !== undefined) {
          data = data.json();
          if (data['success'] == 'true') {
            this.codeValidation = 'Your OTP is expired.';
            $('.customvalidation').show();
          } else if (data['success'] === 'false') {
            this.codeValidation = '';
            this.router.navigate(['/servicereport', Common.encryptId(this.id)]);

          } else if (data['success'] === 'invalid') {
            this.codeValidation = 'Incorrect OTP.Enter the 4-digit OTP sent to you.';
            $('.customvalidation').show();
          }
        }
      });
    }
  }

  generateServiceReport(otp) {
    if (this.OtpForm.valid) {
      this.getOtp(otp.otp);
    } else {
      $('.formvalidation').show();
      this.alertService.validationAlert();
    }
  }

  getComplaintDetail() {
    this.http.get(Configuration.serviceCoreURL  + '/getservicereportcomplaintdetails?id=' + this.id).subscribe(data => {
      data = data.json();
      if ( data != null) {
        this.business_id = data['complaintdetails']['business_id'];
        this.getEmployerDetails();
      }
    });
  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });
    this.title.setTitle('Octanics - OTP Verification');
    this.OtpForm = new FormGroup({
      otp: new FormControl('', Validators.required),
    });

    this.getComplaintDetail();
    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');
    });
    // this.getEmployerDetails();
  }

}
