import { Component, OnInit } from '@angular/core';
import {Utility} from '../../../common/utility';
import {Http, Response} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {Configuration} from '../../../../assets/general/configuration';

@Component({
  selector: 'app-insertpersonalinterview',
  templateUrl: './insertpersonalinterview.component.html',
  styleUrls: ['./insertpersonalinterview.component.scss']
})
export class InsertpersonalinterviewComponent implements OnInit {

  constructor(private http: Http, private title: Title, private route: ActivatedRoute, private router: Router) { }

  candidateid: number;
  questions = [];
  currentYear = Utility.currentYear();

  getPersonalInterviewQuestion = function() {
    this.http.get(Configuration.serviceCoreURL + '/personalinterviewquestionsandanswer').subscribe(data => {
      this.questions = data.json();
    });
  };

  insertPersonalInterview(personalinterview) {
    personalinterview.created_by = -1;
    personalinterview.modified_by = -1;
    personalinterview.candidate_id = this.candidateid;
    personalinterview.candidatepersonalinterviewdetails = this.questions;
    this.http.post(Configuration.serviceCoreURL + '/insertcandidatepersonalinterview', personalinterview).subscribe((res: Response) => {
      this.router.navigate(['/message']);
    });

  }

  ngOnInit() {
    this.title.setTitle('Octanics - PersonalInterview');
    this.route.paramMap.subscribe(params => {
      const paramId = CryptoJS.AES.decrypt(params.get('id'), 'masterKey');
      if (paramId.toString()) {
        this.candidateid = JSON.parse(paramId.toString(CryptoJS.enc.Utf8));

      }
    });
    this.getPersonalInterviewQuestion();
  }

}
