import {Component, OnInit, ViewChild} from '@angular/core';
import {SignaturePad} from 'angular4-signaturepad/signature-pad';
import {Http, Response, ResponseContentType} from '@angular/http';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Utility} from '../../../common/utility';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Common} from '../../../common/common';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';
import {AlertService} from '../../../common/alert.service';
declare var $: any;

@Component({
  selector: 'app-handingoverreport',
  templateUrl: './handingoverreport.component.html',
  styleUrls: ['./handingoverreport.component.scss']
})
export class HandingoverreportComponent implements OnInit {

  // @ts-ignore
  @ViewChild('signaturePadHanded') signaturePadHanded: SignaturePad;
  // @ts-ignore
  @ViewChild('signaturePadAccepted') signaturePadAccepted: SignaturePad;

  constructor(private http: Http, private httpclient: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private title: Title, private lookUp: Lookup,
              private alertService: AlertService) {
  }

  // tslint:disable-next-line:ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 1,
    'canvasWidth': 600,
    'canvasHeight': 200
  };

  id: number;
  todayDate: any;
  jobWorkDetails = [];
  jobWorkAssignmentHistory = [];
  employerDetails: any = [];
  currentYear = Utility.currentYear();
  HandingOverReportForm: FormGroup;
  companyShortName;
  junctionBoxSubDomain;
  pdf: any;
  selectedFilePath: string;
  errorMessage = '';
  successMessage = '';
  systemapi = [];
  amcDetails = [];
  addresstypes = [];
  registeredId: any;
  handedoversignature: any;
  acceptedsignature: any;
  signatureValidationMessage = '';
  acceptedSignatureURL = false;
  handedOverSignatureURL = false;
  acceptedTime: any;
  jobWorkTypeId: number;
  referenceid: number;
  productDetails = [];
  junctionBox;
  employeeDetail;
  employeeId;
  prodreferId: number;
  companyname: string;
  // tslint:disable-next-line:variable-name
  business_id;

  getSystemApis() {
    this.http.get(Configuration.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);
    });

  }


  selectchange(id, jobWorkTypeId) {
    this.prodreferId = id;
    this.jobWorkTypeId = jobWorkTypeId;
    if (this.jobWorkTypeId != null) {
      // tslint:disable-next-line:max-line-length
      this.http.get(Configuration.serviceCoreURL + '/gethandingoverreportproductdetails?id=' + this.prodreferId + '&jobworktypeid=' + this.jobWorkTypeId).subscribe(data => {
        if (data['_body'] !== '' && data['_body'] !== null && data['_body'] !== undefined) {
          this.productDetails = data.json();
        }
      });
    }
  }

  getJobWorkDetail() {
    this.http.get(Configuration.serviceCoreURL + '/gethandingoverreportjobworkdetails?id=' + this.id).subscribe(data => {
      data = data.json();
      if ( data['jobWorkDetails'] !== null && data['jobWorkDetails'] !== undefined && data['jobWorkDetails'] !== '' ) {
        this.jobWorkDetails = data['jobWorkDetails'];
        this.referenceid =  this.jobWorkDetails['reference_id'];
        if (this.jobWorkDetails !== null) {
          this.business_id = this.jobWorkDetails['business_id'];
          localStorage.setItem('business_id', this.business_id);
          // tslint:disable-next-line:no-shadowed-variable
          this.lookUp.getLookUp('addresstypes').subscribe((data) => {
            if (data) {
              this.addresstypes = data;
              const type = this.addresstypes.find(x => x.code === 'REGISTERED');
              this.registeredId = type['id'];
            }
            // tslint:disable-next-line:max-line-length
            this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
              data = data.json();
              this.employerDetails = data;
              localStorage.setItem('companyname', data['nameoforganization']);
              localStorage.setItem('companymobileno', data['mobilenoofowner']);
              localStorage.setItem('companyshortname', data['companyshortname']);
            });
          });
          this.jobWorkAssignmentHistory = data['jobWorkAssignmentHistory'];
          this.selectchange(this.jobWorkDetails['reference_id'], this.jobWorkDetails['jobworktype_id']);
          if (this.jobWorkDetails['companyname'] === '') {
            this.companyname = this.jobWorkDetails['customerName'];
          } else {
            this.companyname = this.jobWorkDetails['companyname'];
          }
        }
      }
    });
  }

  drawStartAccepted() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  drawCompleteAccepted() {
    this.acceptedsignature = this.signaturePadAccepted.toDataURL();
    this.acceptedSignatureURL = true;
  }

  drawStartHandedOver() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  drawCompleteHandedOver() {
    this.handedoversignature = this.signaturePadHanded.toDataURL();
    this.handedOverSignatureURL = true;
  }

  generatePDF() {
    if (this.HandingOverReportForm.valid && this.acceptedSignatureURL && this.handedOverSignatureURL) {
      this.getHandingOverPdf().subscribe(res => {
        return res;
      });
    } else {
      this.signatureValidationMessage = 'We need both signature!';
      this.alertService.validationAlert();
      $('.formvalidation').show();
      $('.customvalidation').show();
    }
  }

  getHandingOverPdf(): any {
    const options = {responseType: ResponseContentType.Blob};
    this.acceptedsignature = encodeURIComponent(this.acceptedsignature);
    this.handedoversignature = encodeURIComponent(this.handedoversignature);
    const documentData = {
      'id': this.id,
      'acceptedsignature': this.acceptedsignature,
      'handedoversignature':this.handedoversignature,
      'jobworktypeid' : this.jobWorkTypeId,
      'referenceid' : this.referenceid
    };
    return this.http.post(Configuration.serviceCoreURL  + '/generatehandingoverpdf', documentData , options).map(
      (res) => {
        this.pdf = new Blob([res.blob()], {type: 'application/pdf'});
        this.uploadDocument(this.pdf);
      });
  }

  public uploadDocument(document: any) {
    this.companyShortName = localStorage.getItem('companyshortname');
    const formData = new FormData();
    formData.append('file', document);
    formData.append('filename', 'handingoverreport');
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/handingoverreport');
    formData.append('subdomain', this.junctionBoxSubDomain);

    const req = new HttpRequest('POST', Utility.serviceStorageURL() + '/savefile', formData, {
      reportProgress: true
    });

    this.httpclient.request(req).subscribe(event => {
      this.selectedFilePath = event['body'];
      if (this.selectedFilePath !== undefined) {
        this.insertDocument();
      }
    });
  }

  insertDocument() {
    const documentData = {
      'jobwork_id': this.id,
      'documentpath': this.selectedFilePath,
      'created_by': this.employeeId,
      'modified_by': this.employeeId
    };
    this.http.post(Configuration.serviceCoreURL  + '/insertjobworkdocument', documentData).subscribe((res: Response) => {
      if (res) {
        this.router.navigate(['/handingoverreportmessage']);
      }
    }, (err) => {
      this.errorMessage = 'Something went wrong';
    });
  }

  ngOnInit() {
    this.getSystemApis();
    this.todayDate = Utility.getDateTime();
    const datePipe = new DatePipe('en-IN');
    this.acceptedTime = datePipe.transform(this.todayDate, 'hh : mm : ss');

    this.title.setTitle('Octanics - Handing Over Report');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });

    this.HandingOverReportForm = new FormGroup({
      aoNo: new FormControl('', null),
      date: new FormControl('', null),

      customerName: new FormControl('', null),
      customerAddress: new FormControl('', null),
      pinCode: new FormControl('', null),
      custContactNo: new FormControl('', null),
      custContactPerson: new FormControl('', null),
      dealerName: new FormControl('', null),
      dealerAddress: new FormControl('', null),
      ph: new FormControl('', null),
      dealerContactPerson: new FormControl('', null),
      handingOverDate: new FormControl('', null),
      startDate: new FormControl('', null),
      endsOn: new FormControl('', null),
      srNo: new FormControl('', null),
      modelNo: new FormControl('', null),
      iduSrNo: new FormControl('', null),
      machineNoOdu: new FormControl('', null),
      cateringLocation: new FormControl('', null),
      dealer: new FormControl('', null),
      handedBy: new FormControl('', null),
      handedName: new FormControl('', null),
      handedContact: new FormControl('', null),
      acceptedBy: new FormControl('', null),
      acceptedName: new FormControl('', null),
      mobileNo: new FormControl('', null),
      acceptedDate: new FormControl('', null),
      acceptedTime: new FormControl('', null),
      termsandcondition: new FormControl('', null),
    });
    this.getJobWorkDetail();


    this.http.get(Configuration.serviceCoreURL  + '/getlastassignmenthistoryemployeeid?id=' + this.id).subscribe(data => {
      this.employeeDetail = data.json();
      this.employeeId = this.employeeDetail != null ? this.employeeDetail['employee_id'] : -1;
    });


    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionBox = localStorage.getItem('junctionBoxPathUrl');

      const junctionboxsubdomainpath = this.systemapi.find(x => x.name === 'junctionboxsubdomain');
      localStorage.setItem('junctionBoxSubDomainPath', junctionboxsubdomainpath.url);
      this.junctionBoxSubDomain = localStorage.getItem('junctionBoxSubDomainPath');

      if (this.junctionBoxSubDomain == 'null') {
        this.junctionBoxSubDomain = null;
      }
    });

  }


}
