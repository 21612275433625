import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LeadfeedbackComponent} from './sales/lead/leadfeedback/leadfeedback.component';
import {ServicereportmessageComponent} from './crm/servicereport/servicereportmessage/servicereportmessage.component';
import {HandingoverreportviewComponent} from './crm/handingoverreport/handingoverreportview/handingoverreportview.component';
import {ServicereportComponent} from './crm/servicereport/servicereport/servicereport.component';
import {OtpverificationComponent} from './crm/otpverification/otpverification.component';
import {NoaccessComponent} from './noaccess/noaccess.component';
import {ServicereportviewComponent} from './crm/servicereport/servicereportview/servicereportview.component';
// tslint:disable-next-line:max-line-length
import {InsertpersonalinterviewComponent} from './humanresources/personalinterview/insertpersonalinterview/insertpersonalinterview.component';
import {McqtestComponent} from './humanresources/mcqtest/mcqtest/mcqtest.component';
import {MessageshowComponent} from './humanresources/messageshow/messageshow.component';
import {CustomerfeedbackComponent} from './sales/customer/customerfeedback/customerfeedback.component';
import {InsertcandidateComponent} from './humanresources/candiate/insertcandidate/insertcandidate.component';
import {FeedbackmessageComponent} from './crm/feedbackmessage/feedbackmessage.component';
import {HandingoverreportmessageComponent} from './crm/handingoverreport/handingoverreportmessage/handingoverreportmessage.component';
import {FeedbackComponent} from './crm/feedback/feedback.component';
import {HandingoverreportComponent} from './crm/handingoverreport/handingoverreport/handingoverreport.component';

const routes: Routes = [
  {path: 'recruitment/:id', component: InsertcandidateComponent},
  {path: 'mcqtest/:id', component: McqtestComponent},
  {path: 'personalinterview/:id', component: InsertpersonalinterviewComponent},
  {path: 'message', component: MessageshowComponent},
  {path: 'feedbackmessage', component: FeedbackmessageComponent},
  {path: 'handingoverreportmessage', component: HandingoverreportmessageComponent},
  {path: 'feedback/:id', component: FeedbackComponent},
  {path: 'customerfeedback/:id', component: CustomerfeedbackComponent},
  {path: 'leadfeedback/:id', component: LeadfeedbackComponent},
  {path: 'servicereport/:id', component: ServicereportComponent},
  {path: 'servicereportview/:id', component: ServicereportviewComponent},
  {path: 'servicereportmessage', component: ServicereportmessageComponent},
  {path: 'handingoverreport/:id', component: HandingoverreportComponent},
  {path: 'handingoverreportview/:id', component: HandingoverreportviewComponent},
  {path: 'otpverification/:id', component: OtpverificationComponent},
  {path: '**', component: NoaccessComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
