import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Utility} from './utility';
import {Configuration} from '../../assets/general/configuration';
import 'rxjs/add/operator/map';

@Injectable()
export class Lookup {
  constructor(private http: Http) {
  }

  getLookUp(value: string, param?: any, employeeId?: string) {
    switch (value) {
      case 'addresstypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=addresstypes').map(res => res.json());
    }

  }
}
