import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Utility} from '../../common/utility';
import {Configuration} from '../../../assets/general/configuration';
import {Lookup} from '../../common/lookup';
import {Http} from '@angular/http';

@Component({
  selector: 'app-feedbackmessage',
  templateUrl: './feedbackmessage.component.html',
  styleUrls: ['./feedbackmessage.component.scss']
})
export class FeedbackmessageComponent implements OnInit {

  constructor(private title: Title, private lookUp: Lookup, private http: Http) {
  }
  currentYear = Utility.currentYear();
  addresstypes = [];
  registeredId: any;
  employerDetails = [];
  junctionbox;
  systemapi;
  // tslint:disable-next-line:variable-name
  business_id = localStorage.getItem('business_id');

  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        const type = this.addresstypes.find(x => x.code === 'REGISTERED');
        this.registeredId = type.id;
      }
      // tslint:disable-next-line:no-shadowed-variable max-line-length
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
      });
    });
  }

  ngOnInit() {
    this.title.setTitle('Octanics - Thank You!');
    this.getEmployerDetails();

    this.http.get(Configuration.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');
    });
  }

}
