import { Component, OnInit } from '@angular/core';
import {Utility} from '../../common/utility';
import {Title} from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-messageshow',
  templateUrl: './messageshow.component.html',
  styleUrls: ['./messageshow.component.scss']
})
export class MessageshowComponent implements OnInit {

  constructor(private title: Title) { }

  currentYear = Utility.currentYear();

  ngOnInit() {
    this.title.setTitle('Octanics - Thank You!');
  }
}
