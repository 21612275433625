import { Component, OnInit } from '@angular/core';

import {Utility} from '../../../common/utility';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';
import {Common} from '../../../common/common';
import {AlertService} from '../../../common/alert.service';
import {Http} from '@angular/http';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-insertcandidate',
  templateUrl: './insertcandidate.component.html',
  styleUrls: ['./insertcandidate.component.scss']
})
export class InsertcandidateComponent implements OnInit {


  constructor(private http: Http, private router: Router, private httpclient: HttpClient,
              private route: ActivatedRoute, private lookUp: Lookup, private title: Title, private alertService: AlertService) {
  }
  candiddateForm: FormGroup;
  id: number;
  candidateid: number;
  selectedFile: ImageSnippet;
  selectedFilePath: string;
  coverNote: ImageSnippet;
  coverNotePath: string;
  companyShortName = Utility.getCompanyShortNameURL();
  employerDetails: any;
  addresstypes = [];
  registeredId: any;
  junctionbox = null;
  systemapi = [];
  uploadingFilePercents = 0;
  uploadingFile = false;
  uploadingCoverNotePercents = 0;
  uploadingCoverNote = false;
  currentYear = Utility.currentYear();
  jobOpeningDetails = [];
  companyname;
  jobname;
  department;
  // tslint:disable-next-line:variable-name
  business_id;
  //
  getSystemApis(){
    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');
    });
  }
  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        const type = this.addresstypes.find(x => x.code === 'REGISTERED');
        this.registeredId = type.id;
      }
      // tslint:disable-next-line:max-line-length no-shadowed-variable
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
        localStorage.setItem('companyname', this.employerDetails['nameoforganization']);
        localStorage.setItem('companymobileno', this.employerDetails['mobilenoofowner']);
        localStorage.setItem('companyshortname', this.employerDetails['companyshortname']);
      });
    });
  }
  getJobDetail() {
    this.http.get(Configuration.serviceCoreURL  + '/jobopeningdetails?id=' + this.id).subscribe(data => {
      if (data['_body'] !== '' && data['_body'] !== null && data['_body'] !== undefined) {
        data = data.json();
        this.jobOpeningDetails = data['jobdetail'];
        this.business_id = this.jobOpeningDetails['business_id'];
        this.getEmployerDetails();
        if (this.jobOpeningDetails !== null && this.jobOpeningDetails !== undefined) {
          this.companyname = this.jobOpeningDetails['companyname'];
          this.jobname = this.jobOpeningDetails['name'];
          this.department = this.jobOpeningDetails['department'];
        }
      }
    });

  }

  uploadMultiple(event) {
    if (event.target.files) {
      for ( let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (events: any) => {
          this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
          this.uploadDocument(this.selectedFile.file);
        };
      }
    }
  }

  public uploadDocument(document: any) {
    const formData = new FormData();
    formData.append('file', document);
    formData.append('filename', document.name);
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/candidatedocuments');

    const req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
      reportProgress: true
    });
    this.httpclient.request(req).subscribe(event => {
      this.selectedFilePath = event['body'];
      // Via this API, you get access to the raw event stream.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
        this.uploadingFile = true;
        // Look for upload progress events.
      } else if (event instanceof HttpResponse) {
        this.uploadingFile = false;
      }
    });
  }

  uploadCovernote(event) {
    if (event.target.files) {
      for ( let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (events: any) => {
          this.coverNote = new ImageSnippet(events.target.result[i], event.target.files[i]);
          this.uploadCover(this.coverNote.file);
        };
      }
    }
  }

  public uploadCover(document: any) {
    const formData = new FormData();
    formData.append('file', document);
    formData.append('filename', document.name);
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/candidatedocuments');

    const req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
      reportProgress: true
    });
    this.httpclient.request(req).subscribe(event => {
      this.coverNotePath = event['body'];
      // Via this API, you get access to the raw event stream.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        this.uploadingCoverNotePercents = Math.round(100 * event.loaded / event.total);
        this.uploadingCoverNote = true;
        // Look for upload progress events.
      } else if (event instanceof HttpResponse) {
        this.uploadingCoverNote = false;
      }
    });
  }

  insertCandidate(candidate) {
    if (this.candiddateForm.valid) {
      candidate.jobopening_id = this.id;
      candidate.documentpath = this.selectedFilePath ? this.selectedFilePath : null;
      candidate.covernote_path = this.coverNotePath ? this.coverNotePath : null;
      candidate.created_by = -1;
      candidate.modified_by = -1;
      // @ts-ignore
      this.http.post(Configuration.serviceCoreURL  + '/insertcandidatejobopening', candidate).subscribe((res: Response) => {
        const candidateId = res.json()['id'];
        this.candidateid = CryptoJS.AES.encrypt(JSON.stringify(candidateId), 'masterKey').toString();
        this.router.navigate(['/mcqtest', this.candidateid]);
      });
    } else {
      this.alertService.validationAlert();
    }
  }

  ngOnInit() {
    this.title.setTitle('Octanics - Candidate');

    this.route.paramMap.subscribe(params => {
      this.id =  Common.decryptId((params.get('id')));
    });
    this.getJobDetail();

    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function() {
      // tslint:disable-next-line:only-arrow-functions
      $('#attach').click(function() {
        $('#covernote_path').show();
        $('#covernote').hide();
      });
      // tslint:disable-next-line:only-arrow-functions
      $('#write').click( function() {
        $('#covernote').show();
        $('#covernote_path').hide();
      });
    });

    this.getSystemApis();
    this.getEmployerDetails();

    const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    this.candiddateForm = new FormGroup({
      policy: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      documentpath: new FormControl('',  Validators.required),
      mobileno: new FormControl('', Validators.required),
      covernote_attach: new FormControl(null),
      covernote_path: new FormControl(null ),
      currentctc: new FormControl(null),
      expectedctc: new FormControl(null ),
      noticeperiod: new FormControl(null),
      relocate: new FormControl(null ),
      covernote: new FormControl('',  [Validators.maxLength(2000)])
    });
  }


}
