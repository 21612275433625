import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from '../../../common/utility';
import {Title} from '@angular/platform-browser';
import {Common} from '../../../common/common';
import {Configuration} from '../../../../assets/general/configuration';

import {Http, Response} from '@angular/http';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-mcqtest',
  templateUrl: './mcqtest.component.html',
  styleUrls: ['./mcqtest.component.scss']
})
export class McqtestComponent implements OnInit {

  constructor(private http: Http, private title: Title, private route: ActivatedRoute, private router: Router) {
  }
  Common = Common;
  questions = [];
  answers = [];
  candidateid: number;
  id: number;
  showMsg = false;
  encrptId: number;
  currentYear = Utility.currentYear();

  getMCQQuestion() {
    this.http.get(Configuration.serviceCoreURL  + '/mcqquestions').subscribe(data => {
      this.questions = data.json();
    });
  }

  getMCQAnswer() {
    this.http.get(Configuration.serviceCoreURL  + '/mcqanswerbymcqquestionid').subscribe(data => {
      this.answers = data.json();
    });
  }

  filterItemsOfType(id) {
    return this.answers.filter(x => x.questionnaire_id === id);
  }

  insertMCQTest(mcqtest) {
    Object.keys(mcqtest).map((key) => {
      mcqtest.created_by = -1;
      mcqtest.modified_by = -1;
      mcqtest.questionnaire_id = key;
      mcqtest.answers_id = mcqtest[key];
      mcqtest.candidate_id = this.candidateid;
      this.http.post(Configuration.serviceCoreURL  + '/insertcandidatemcq', mcqtest).subscribe((res: Response) => {
        this.router.navigate(['/personalinterview', Common.encryptId(this.candidateid)]);
      });
    });
  }

  ngOnInit() {
    this.title.setTitle('Octanics - MCQTest');
    // this.route.paramMap.subscribe(params => {
    //   const paramId = CryptoJS.AES.decrypt(params.get('id'), 'masterKey');
    //   if (paramId.toString()) {
    //     this.candidateid = JSON.parse(paramId.toString(CryptoJS.enc.Utf8));
    //     this.encrptId= CryptoJS.AES.encrypt(JSON.stringify(this.candidateid), 'masterKey').toString();
    //   }
    // });
    this.route.paramMap.subscribe(params => {
      this.candidateid =  Common.decryptId((params.get('id')));
    });
    this.getMCQQuestion();
    this.getMCQAnswer();
  }

}
