export class Configuration {
 public static serviceCoreURL = 'http://localhost:8001'; /*local*/
 // public static serviceCoreURL = "https://profittrackerservicecore.octanics.co"; /*server*/
// public static serviceCoreURL = "https://turbobusinessservicecore.octanics.co"; /*server*/
// public static serviceCoreURL = "https://trainingservicecore.octanics.co"; /*server*/
// public static serviceCoreURL = "https://salesservicecore.octanics.co"; /*server*/
// public static  serviceCoreURL= "https://swastikservicecore.octanics.io"; /*Swastik*/
// public static  serviceCoreURL= "https://packagingservicecore.octanics.io"; /*Packaging*/
// public static serviceCoreURL = "https://mandoviservicecore.octanics.io/"; /* Mandovi Furniture*/

// public static serviceCoreURL = "https://sarvadnyaservicecore.octanics.io"; /*Sarvadnya*/

// public static serviceStorageURL = 'http://localhost:8003';
// public static  serviceStorageURL= "https://profittrackerservicestorage.octanics.co";
//   public static serviceCoreURL = "https://graceglobalsdnbhdservicecore.octanics.io"; /*GraceGlobals*/
//   public static serviceCoreURL = "https://amchemservicecore.octanics.io"; /*Amchem*/
}
