import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeadfeedbackComponent } from './sales/lead/leadfeedback/leadfeedback.component';
import { CustomerfeedbackComponent } from './sales/customer/customerfeedback/customerfeedback.component';
import { NoaccessComponent } from './noaccess/noaccess.component';
import { ValidationerrorComponent } from './validationerror/validationerror.component';
import { InsertcandidateComponent } from './humanresources/candiate/insertcandidate/insertcandidate.component';
import { McqtestComponent } from './humanresources/mcqtest/mcqtest/mcqtest.component';
import { MessageshowComponent } from './humanresources/messageshow/messageshow.component';
import { InsertpersonalinterviewComponent } from './humanresources/personalinterview/insertpersonalinterview/insertpersonalinterview.component';
import { FeedbackmessageComponent } from './crm/feedbackmessage/feedbackmessage.component';
import { HandingoverreportmessageComponent } from './crm/handingoverreport/handingoverreportmessage/handingoverreportmessage.component';
import { HandingoverreportviewComponent } from './crm/handingoverreport/handingoverreportview/handingoverreportview.component';
import { OtpverificationComponent } from './crm/otpverification/otpverification.component';
import { ServicereportComponent } from './crm/servicereport/servicereport/servicereport.component';
import { ServicereportmessageComponent } from './crm/servicereport/servicereportmessage/servicereportmessage.component';
import { ServicereportviewComponent } from './crm/servicereport/servicereportview/servicereportview.component';
import {HttpModule} from '@angular/http';
import {SignaturePadModule} from 'angular4-signaturepad';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HandingoverreportComponent} from './crm/handingoverreport/handingoverreport/handingoverreport.component';
import {Lookup} from './common/lookup';
import {AlertService} from './common/alert.service';
import {FeedbackComponent} from './crm/feedback/feedback.component';

@NgModule({
  declarations: [
    AppComponent,
    LeadfeedbackComponent,
    CustomerfeedbackComponent,
    FeedbackComponent,
    NoaccessComponent,
    ValidationerrorComponent,
    InsertcandidateComponent,
    McqtestComponent,
    MessageshowComponent,
    InsertpersonalinterviewComponent,
    FeedbackmessageComponent,
    HandingoverreportComponent,
    HandingoverreportmessageComponent,
    HandingoverreportviewComponent,
    OtpverificationComponent,
    ServicereportComponent,
    ServicereportmessageComponent,
    ServicereportviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SignaturePadModule
  ],
  providers: [
    Lookup,
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
