import { Component, OnInit } from '@angular/core';
import {Utility} from '../../../common/utility';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-servicereportmessage',
  templateUrl: './servicereportmessage.component.html',
  styleUrls: ['./servicereportmessage.component.scss']
})
export class ServicereportmessageComponent implements OnInit {

  constructor(private title: Title) { }

  currentYear = Utility.currentYear();

  ngOnInit() {
    this.title.setTitle('Octanics - Thank You!');
  }

}
