import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Response, ResponseContentType} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Utility} from "../../../common/utility";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Common} from '../../../common/common';
import {SignaturePad} from 'angular4-signaturepad/signature-pad';
import 'rxjs/Rx';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';

declare var $: any;

@Component({
  selector: 'app-servicereportview',
  templateUrl: './servicereportview.component.html',
  styleUrls: ['./servicereportview.component.scss']
})
export class ServicereportviewComponent implements OnInit {

  // @ts-ignore
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  constructor(private http: Http, private httpclient: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private title: Title, private lookUp: Lookup) {
  }

  // tslint:disable-next-line:ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 1,
    'canvasWidth': 400,
    'canvasHeight': 100
  };

  id: number;
  employeeId: number;
  complaintDetails = [];
  complaintProductDetails = [];
  employerDetails = [];
  addresstypes = [];
  registeredId: any;
  currentYear = Utility.currentYear();
  ServiceReportForm: FormGroup;
  companyShortName = Utility.getCompanyShortNameURL();
  systemapi = [];
  employeedetail: object = {};
  junctionbox;
  junctionboxsubdomainbox;
  date: any;
  address: string;
  companyname: string;
  // tslint:disable-next-line:variable-name
  business_id;

  getSystemApis() {
    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);
    });
  }

  getComplaintDetail() {
    this.http.get(Configuration.serviceCoreURL  + '/getservicereportcomplaintdetails?id=' + this.id).subscribe(data => {
      data = data.json();
      this.complaintDetails = data['complaintdetails'];
      this.business_id = this.complaintDetails['business_id'];
      this.getEmployerDetails();
      localStorage.setItem('business_id', this.business_id);
      this.complaintProductDetails = data['complaintproductdetails'];
      if (this.complaintDetails['companyname'] === '') {
        this.companyname = this.complaintDetails['customer'];
      } else {
        this.companyname = this.complaintDetails['companyname'];
      }

      // tslint:disable-next-line:prefer-const
      let addressdata = [this.complaintDetails['street1'], this.complaintDetails['street2'], this.complaintDetails['street3'],
        this.complaintDetails['city'], this.complaintDetails['state'], this.complaintDetails['country']];
      this.address = addressdata.filter(Boolean).join(', ');
    });
  }
  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        const type = this.addresstypes.find(x => x.code === 'REGISTERED');
        this.registeredId = type['id'];
      }
      // tslint:disable-next-line:max-line-length
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
      });
    });
  }

  ngOnInit() {
    this.getSystemApis();
    this.title.setTitle('Octanics - Service Report');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });

    this.http.get(Configuration.serviceCoreURL  + '/getlastassignmenthistoryemployeeid?id=' + this.id).subscribe(data => {
      this.employeedetail = data.json();
      this.employeeId = this.employeedetail != null ? this.employeedetail['employee_id'] : -1;
    });

    this.ServiceReportForm = new FormGroup({
      termsandcondition: new FormControl('', null),
    });

    this.date = new Date();

    this.getComplaintDetail();
    // this.getEmployerDetails();

    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');

      const junctionboxsubdomainpath = this.systemapi.find(x => x.name === 'junctionboxsubdomain');
      localStorage.setItem('junctionBoxSubDomainPath', junctionboxsubdomainpath.url);
      this.junctionboxsubdomainbox = localStorage.getItem('junctionBoxSubDomainPath');

      if (this.junctionboxsubdomainbox === 'null') {
        this.junctionboxsubdomainbox = null;
      }
    });

    this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId).subscribe(data => {
      data = data.json();
      localStorage.setItem('companyname', data['nameoforganization']);
      localStorage.setItem('companymobileno', data['mobilenoofowner']);
      localStorage.setItem('companyshortname', data['companyshortname']);
    });
  }


}
