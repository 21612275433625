import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {Http, Response, Headers} from '@angular/http';
import * as CryptoJS from 'crypto-js';
import {Title} from '@angular/platform-browser';
import {Utility} from '../../../common/utility';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Common} from '../../../common/common';
import {SignaturePad} from 'angular4-signaturepad/signature-pad';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';
@Component({
  selector: 'app-leadfeedback',
  templateUrl: './leadfeedback.component.html',
  styleUrls: ['./leadfeedback.component.scss']
})
export class LeadfeedbackComponent implements OnInit {

  // @ts-ignore
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  constructor(private http: Http,
              private router: Router,
              private route: ActivatedRoute,
              private title: Title, private lookUp: Lookup) {
  }

  // tslint:disable-next-line:ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 1,
    'canvasWidth': 400,
    'canvasHeight': 200
  };

  id: number;
  questions = [];
  junctionbox;
  answers = [];
  systemapi = [];
  customerDetails = [];
  employerDetails = [];
  addresstypes = [];
  registeredId: any;
  currentYear = Utility.currentYear();
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number;
  ratings: object = {};
  digitalsignature: object = {};
  customerfeedback = [];
  googlereviewlink = [] ;
  youtubelink = [];
  instagramlink = [];
  // tslint:disable-next-line:variable-name
  business_id;

  goToGoogleReview() {
    window.open('https://' + this.googlereviewlink['link'], '_blank');
  }
  goToYoutube() {
    window.open('https://' + this.youtubelink['link'], '_blank');
  }
  goToInstagram() {
    window.open('https://' + this.instagramlink['link'], '_blank');
  }

  getCustomerFeedbackQuestions() {
    this.http.get(Configuration.serviceCoreURL + '/leadfeedbackquestions').subscribe(data => {
      data = data.json();
      console.log(data);
      this.questions = data['questionnaire'];
      for (const i of this.questions) {
        const customerfeedback = {
          question_id: i.id,
          answer: null,
          controltype: null
        };
        this.customerfeedback.push(customerfeedback);
      }
      this.googlereviewlink = data['googlereviewlink'];
      this.youtubelink = data['youtubelink'];
      console.log(this.youtubelink);
      this.instagramlink = data['instagramlink'];
      // console.log(this.googlereviewlink);
    });
  }

  getComplaintDetail() {
    this.http.get(Configuration.serviceCoreURL + '/feedbackcustomerdetail?id=' + this.id).subscribe(data => {
      this.customerDetails = data.json();
      this.business_id = this.customerDetails['business_id'];
      localStorage.setItem('business_id', this.business_id);
      this.getEmployerDetails();
    });
  }

  getAnswers() {
    this.http.get(Configuration.serviceCoreURL + '/feedbackanswers').subscribe(data => {
      this.answers = data.json();
    });
  }

  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        let type = this.addresstypes.find(x => x.code == 'REGISTERED');
        this.registeredId = type['id'];
      }
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
      });
    });
  }

  filterItemsOfType(id) {
    return this.answers.filter(x => x.questionnaire_id === id);
  }

  countStar(star, question_id) {
    this.selectedValue = star;
    for (let i in this.customerfeedback){
      if (this.customerfeedback[i]['question_id'] == question_id) {
        this.customerfeedback[i]['answer'] = star;
        this.customerfeedback[i]['controltype'] = 'RATING';
      }
    }
  }

  setAnswer(question_id, answer, args, controltype) {
    for (let i in this.customerfeedback){
      if (this.customerfeedback[i]['question_id'] == question_id) {
        if (controltype != 'RADIOBUTTON') {
          this.customerfeedback[i]['answer'] = args.target.value;
          this.customerfeedback[i]['controltype'] = controltype;
        } else {
          this.customerfeedback[i]['answer'] = answer;
          this.customerfeedback[i]['controltype'] = controltype;
        }
      }
    }
  }

  drawComplete(question_id) {
    // will be notified of szimek/signature_pad's onEnd event
    for (let i in this.customerfeedback){
      if (this.customerfeedback[i]['question_id'] == question_id) {
        this.customerfeedback[i]['answer'] = this.signaturePad.toDataURL();
        this.customerfeedback[i]['controltype'] = 'DIGITALSIGNATURE';
      }
    }
  }

  insertcustomerfeedback(feedback) {
    const result = Object.keys(feedback);
    // tslint:disable-next-line:only-arrow-functions
    const keys = result.map(function(x) {
      return parseInt(x, 10);
    });

    // tslint:disable-next-line:forin
    for (const i in this.customerfeedback) {
      this.customerfeedback[i]['question_id'] = parseInt(this.customerfeedback[i]['question_id']);
    }
    for (const i in this.customerfeedback) {
      if (keys.includes(this.customerfeedback[i]['question_id'])) {
        this.customerfeedback[i]['answer'] = feedback[this.customerfeedback[i]['question_id']] != undefined ? feedback[this.customerfeedback[i]['question_id']] : null;
      }
    }

    const customerfeedback = {
      customerfeedbacks: this.customerfeedback,
      customer_id: this.id
    };

    this.http.post(Configuration.serviceCoreURL + '/insertcustomerfeedback', customerfeedback).subscribe((res: Response) => {
      this.router.navigate(['/feedbackmessage']);
    });

    /*Object.keys(feedback).map((key) => {
      feedback.created_by = Utility.getLoggedUserId();
      feedback.modified_by = Utility.getLoggedUserId();
      feedback.question_id = key;
      feedback.complaint_id = this.id;

      this.http.post(Configuration.serviceCoreURL  + '/insertcustomerfeedback', feedback).subscribe((res: Response) => {
        this.router.navigate(['/feedbackmessage']);
      });
    });*/
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  ngOnInit() {
    this.title.setTitle('Octanics - Lead Feedback');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });
    this.http.get(Configuration.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');
    });

    this.getCustomerFeedbackQuestions();
    this.getComplaintDetail();
    this.getAnswers();
    // this.getEmployerDetails();
  }
}
