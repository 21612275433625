import { Component, OnInit } from '@angular/core';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.scss']
})
export class NoaccessComponent implements OnInit {

  constructor(private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle('Octanics - Noaccess page ');
  }

}
