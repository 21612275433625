import { Component, OnInit } from '@angular/core';
import {Utility} from '../../../common/utility';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-handingoverreportmessage',
  templateUrl: './handingoverreportmessage.component.html',
  styleUrls: ['./handingoverreportmessage.component.scss']
})
export class HandingoverreportmessageComponent implements OnInit {

  constructor(private title: Title) { }

  currentYear = Utility.currentYear();

  ngOnInit() {
    this.title.setTitle('Octanics - Thank You!');
  }

}
