import {Component, OnInit, ViewChild} from '@angular/core';
import {SignaturePad} from 'angular4-signaturepad/signature-pad';
import {Http, Response, ResponseContentType} from '@angular/http';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Utility} from '../../../common/utility';
import {FormControl, FormGroup} from '@angular/forms';
import {Common} from '../../../common/common';
import {Configuration} from '../../../../assets/general/configuration';
import {Lookup} from '../../../common/lookup';
import {AlertService} from '../../../common/alert.service';

declare var $: any;
@Component({
  selector: 'app-servicereport',
  templateUrl: './servicereport.component.html',
  styleUrls: ['./servicereport.component.scss']
})
export class ServicereportComponent implements OnInit {


  // @ts-ignore
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  constructor(private http: Http, private httpclient: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private title: Title, private lookUp: Lookup) { }

  // tslint:disable-next-line:ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 1,
    'canvasWidth': 500,
    'canvasHeight': 183
  };

  id: number;
  employeeId: number;
  complaintDetails = [];
  complaintProductDetails = [];
  employerDetails = [];
  currentYear = Utility.currentYear();
  ServiceReportForm: FormGroup;
  companyShortName;
  pdf: any;
  selectedFilePath: string;
  errorMessage = '';
  successMessage = '';
  systemapi = [];
  digitalsignature: any;
  registeredId: number;
  addresstypes = [];
  signatureValidationMessage = '';
  signatureURL = false;
  employeedetail: object = {};
  junctionbox;
  junctionboxsubdomainbox;
  date: any;
  address: string;
  companyname: string;
  // tslint:disable-next-line:variable-name
  business_id;

  getSystemApis() {
    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);
    });
  }

  getComplaintDetail() {
    this.http.get(Configuration.serviceCoreURL + '/getservicereportcomplaintdetails?id=' + this.id).subscribe(data => {
      data = data.json();
      this.complaintDetails = data['complaintdetails'];
      this.business_id = this.complaintDetails['business_id'];
      localStorage.setItem('business_id', this.business_id);
      this.getEmployerDetails();
      this.complaintProductDetails = data['complaintproductdetails'];
      if (this.complaintDetails['companyname'] == '') {
        this.companyname = this.complaintDetails['customer'];
      } else {
        this.companyname = this.complaintDetails['companyname'];
      }

      // tslint:disable-next-line:prefer-const
      let addressdata = [this.complaintDetails['street1'], this.complaintDetails['street2'], this.complaintDetails['street3'],
        this.complaintDetails['city'], this.complaintDetails['state'], this.complaintDetails['country']];
      this.address = addressdata.filter(Boolean).join(', ');
    });
  }

  getEmployerDetails() {
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
      if (data) {
        this.addresstypes = data;
        const type = this.addresstypes.find(x => x.code === 'REGISTERED');
        this.registeredId = type['id'];
      }
      // tslint:disable-next-line:max-line-length
      this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(data => {
        this.employerDetails = data.json();
        localStorage.setItem('companyname',  this.employerDetails['nameoforganization']);
        localStorage.setItem('companymobileno',  this.employerDetails['mobilenoofowner']);
        localStorage.setItem('companyshortname',  this.employerDetails['companyshortname']);
      });
    });
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  drawComplete() {
    this.digitalsignature = this.signaturePad.toDataURL();
    this.signatureURL = true;
  }

  generatePDF() {
    if (this.ServiceReportForm.valid && this.signatureURL === true) {
      this.getServiceReportPdf().subscribe(res => {
        return res;
      });
    } else {
      this.signatureValidationMessage = 'Please sign the service report form';
      this.alertService.validationAlert();
      $('.formvalidation').show();
      $('.customvalidation').show();
    }
  }

  getServiceReportPdf(): any {
    const options = {responseType: ResponseContentType.Blob};
    this.digitalsignature = encodeURIComponent(this.digitalsignature);
    const documentData = {
      'id': this.id,
      'digitalsignature': this.digitalsignature,
    };
    return this.http.post(Configuration.serviceCoreURL  + '/generateservicereportpdf', documentData , options).map(
      (res) => {
        this.pdf = new Blob([res.blob()], {type: 'application/pdf'});
        this.uploadDocument(this.pdf);
      });

  }

  public uploadDocument(document: any) {
    this.companyShortName = localStorage.getItem('companyshortname');
    const formData = new FormData();
    formData.append('file', document);
    formData.append('filename', 'servicereport');
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/servicereport');
    formData.append('subdomain', this.junctionboxsubdomainbox);
    const req = new HttpRequest('POST', Utility.serviceStorageURL() + '/savefile', formData, {
      reportProgress: true
    });
    this.httpclient.request(req).subscribe(event => {
      this.selectedFilePath = event['body'];
      if (this.selectedFilePath !== undefined) {
        this.insertDocument();
      }
    });
  }

  insertDocument() {
    const documentData = {
      'complaint_id': this.id,
      'documentpath': this.selectedFilePath,
      'created_by': this.employeeId,
      'modified_by': this.employeeId
    };

    this.http.post(Configuration.serviceCoreURL  + '/insertcomplaintdocument', documentData).subscribe((res: Response) => {
      if (res) {
        this.router.navigate(['/servicereportmessage']);
      }
    }, (err) => {
      this.errorMessage = 'Something went wrong';
    });
  }



  ngOnInit() {
    this.getSystemApis();
    this.title.setTitle('Octanics - Service Report');

    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });

    this.http.get(Configuration.serviceCoreURL  + '/getlastassignmenthistoryemployeeid?id=' + this.id).subscribe(data => {
      this.employeedetail = data.json();
      this.employeeId = this.employeedetail != null ? this.employeedetail['employee_id'] : -1;
    });

    this.ServiceReportForm = new FormGroup({
      termsandcondition: new FormControl('',null),
    });

    this.date = new Date();

    this.getComplaintDetail();


    this.http.get(Configuration.serviceCoreURL  + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata.json();
      const storage = this.systemapi.find(x => x.name === 'storage');
      localStorage.setItem('serviceStorageURL', storage.url);

      const junctionboxpath = this.systemapi.find(x => x.name === 'junctionboxpath');
      localStorage.setItem('junctionBoxPathUrl', junctionboxpath.url);
      this.junctionbox = localStorage.getItem('junctionBoxPathUrl');

      const junctionboxsubdomainpath = this.systemapi.find(x => x.name === 'junctionboxsubdomain');
      localStorage.setItem('junctionBoxSubDomainPath', junctionboxsubdomainpath.url);
      this.junctionboxsubdomainbox = localStorage.getItem('junctionBoxSubDomainPath');

      if (this.junctionboxsubdomainbox === 'null') {
        this.junctionboxsubdomainbox = null;
      }
    });

  }


}
